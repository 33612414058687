/* Place your extensions here to make migration to a new release easier. */
div .p-col-12,
.p-md-12,
.p-lg-12,
.p-col-11,
.p-md-11,
.p-lg-11,
.p-col-10,
.p-md-10,
.p-lg-10,
.p-col-10,
.p-md-10,
.p-lg-10,
.p-col-9,
.p-md-9,
.p-lg-9,
.p-col-9,
.p-md-9,
.p-lg-9,
.p-col-8,
.p-md-8,
.p-lg-8,
.p-col-8,
.p-md-8,
.p-lg-8,
.p-col-7,
.p-md-7,
.p-lg-7,
.p-col-7,
.p-md-7,
.p-lg-7,
.p-col-6,
.p-md-6,
.p-lg-6,
.p-col-6,
.p-md-6,
.p-lg-6,
.p-col-5,
.p-md-5,
.p-lg-5,
.p-col-5,
.p-md-5,
.p-lg-5,
.p-col-4,
.p-md-4,
.p-lg-4,
.p-col-4,
.p-md-4,
.p-lg-4,
.p-col-3,
.p-md-3,
.p-lg-3,
.p-col-3,
.p-md-3,
.p-lg-3,
.p-col-2,
.p-md-2,
.p-lg-2,
.p-col-2,
.p-md-2,
.p-lg-2,
.p-col-1,
.p-md-1,
.p-lg-1,
.p-col-1,
.p-md-1,
.p-lg-1 {
  padding: 4px;
}

body .p-button.p-button-secondary,
body .p-buttonset.p-button-secondary > .p-button {
  color: #ffffff;
  background-color: #686868;
  border: 1px solid #686868;
}
body .p-button.p-button-secondary:enabled:hover,
body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
  background-color: #505050;
  color: #ffffff;
  border-color: #505050;
}
body .p-button.p-button-secondary:enabled:focus,
body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .p-button.p-button-secondary:enabled:active,
body .p-buttonset.p-button-secondary > .p-button:enabled:active {
  color: #ffffff;
  background-color: #686868;
  border: 1px solid #686868;
}

// danger

body .p-button.p-button-danger,
body .p-buttonset.p-button-danger > .p-button {
  color: #ffffff;
  background-color: #db2b3c;
  border: 1px solid #db2b3c;
}
body .p-button.p-button-danger:enabled:hover,
body .p-buttonset.p-button-danger > .p-button:enabled:hover {
  background-color: #b31f2e;
  color: #ffffff;
  border-color: #b31f2e;
}
body .p-button.p-button-danger:enabled:focus,
body .p-buttonset.p-button-danger > .p-button:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #f9b4ba;
  -moz-box-shadow: 0 0 0 0.2em #f9b4ba;
  box-shadow: 0 0 0 0.2em #f9b4ba;
}
body .p-button.p-button-danger:enabled:active,
body .p-buttonset.p-button-danger > .p-button:enabled:active {
  background-color: #a90000;
  color: #ffffff;
  border-color: #a90000;
}

//configurações gerais para disabled
body .p-disabled,
body .p-component:disabled {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
// configuração do disabled para buttons
body .p-component.p-button:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.p-menuitem.p-disabled { /*Configura o item do menu para ficar da forma q era*/
  opacity: .50 !important;
  filter: Alpha(Opacity = .50) !important;
  background-image: none;
  background: #fafaf6;
}

//configurações da seleção de linha no datatable
body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #0b95ff;
  color: #ffffff;
}
body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background-color: #0b95ff;
  color: #ffffff;
}

//gerais
a,
button {
  margin-right: 0.25em;
}

body .p-button {
  margin-right: 2px;
}
